<template>
  <div>
    <v-dialog
      v-model="activo"
      :max-width="sonSeries == 0 ? '50%' : '80%'"
    >
      <v-card
        flat
      >
        <v-card-title
          class="pt-0"
          :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
        >
          <div v-if="conArchivo == 0">Carga Asistida</div>
          <div v-if="conArchivo == 1">Importar Archivo</div>
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <br/>
        <v-card-text>
          <!-- Código de Artículo o de Barras, escanear -->
          <v-row v-if="conArchivo == 0">
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-form
                @submit.prevent="agregarInput()"
              >
                <!-- Por Cód. Barras -->
                <v-col cols="12" class="py-0">
                  <div v-if="tipoIngreso == true">Cód. Barras</div>
                  <div v-if="tipoIngreso == false">Cód. BB</div>
                  <v-text-field
                    v-model="articulo"
                    outlined
                    dense
                    :type="tipoIngreso == false ? 'number' : 'text'"
                    ref="redArticulo"
                    autofocus
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <br/>
              <v-switch
                v-model="tipoIngreso"
                :label="tipoIngreso == false ? 'Ingreso por Cód. de Artículos' : 'Ingreso por Cód. de Barras'"
                class="d-flex align-center py-0 my-0"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
          <!-- Importar Archivo -->
          <v-row
            class="d-flex justify-center"
            style="border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer;"
            :style="$vuetify.theme.dark ? 'background-color: #0f0f0f;' : 'background-color: #f0f0f0;'"
            no-gutters
            @dragover.prevent="hover = true"
            @dragleave.prevent="hover = false"
            @drop.prevent="drop"
            @click="$refs.input.click()"
            v-if="conArchivo == 1"
          >
            <input
              ref="input"
              type="file"
              class="d-none"
              :accept="tiposValidos.join(',')"
              @change="buscar($event)"
            >
            <v-col cols="12" class="d-flex justify-center mt-6">
              <v-icon>fas fa-folder</v-icon>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-2 mb-2">
              <h1 class="body-1 font-weight-bold mx-4 text-center">
                Haga click aquí para empezar
              </h1>
            </v-col>
          </v-row>
          <div
            class="d-flex body-2 pt-2"
            style="cursor: default"
            v-if="conArchivo == 1"
          >
            <div>
              Tipo{{ tiposValidos.length > 1 ? 's' : '' }} de archivo válido: {{ tiposValidos.join(', ') }}
            </div>
          </div>
          <!-- Detalle de Artículos, no artículos con Serie -->
          <v-row v-if="sonSeries == 0">
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="detalles"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Aún no se escanearon productos.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-btn icon small title="Quitar" @click="quitarArticulo(item)"><v-icon small color="error">fas fa-times</v-icon></v-btn>
                  <v-btn icon small title="Inexistente" @click="mostrarMsjInexistente(item)" v-if="item.inexistente == 1"><v-icon small color="warning">fas fa-question</v-icon></v-btn>
                  <v-btn icon small title="Debe ingresarse con Series" @click="mostrarMsjSolicitaSerie(item)" v-if="item.solicita_serie == 1"><v-icon small color="success">fas fa-list</v-icon></v-btn>
                  <v-btn icon small title="Cod. de Barras asociado a más de un artículo" @click="resolverRepetidos(item)" v-if="item.repetido == 1"><v-icon small color="indigo">fas fa-th</v-icon></v-btn>
                  <v-btn icon small title="Sin Stock" @click="mostrarMsjNoStock(item)" v-if="item.sin_stock == 1"><v-icon small color="warning">fas fa-exclamation-triangle</v-icon></v-btn>
                  <v-btn icon small title="Pruebe remitir otra cantidad." @click="resolverStockInsuficiente(item)" v-if="item.dif_stock == 1"><v-icon small color="swalText">fas fa-exclamation</v-icon></v-btn>
                  <v-btn icon small title="Artículo sin precio definido." @click="mostrarMsjSinPrecio(item)" v-if="item.sin_precio == 1"><v-icon small color="orange">fas fa-tag</v-icon></v-btn>
                </template>
                <!-- Items -->
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field
                      v-model="item.cantidad"
                      style="width: 50px"
                      type="number"
                      hide-details
                      dense
                      outlined
                      @blur="quitarStockInsuficiente(item)"
                    ></v-text-field>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- Detalles de Artículos, solo para artículos con Serie -->
          <v-row v-if="sonSeries == 1">
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  Series que no se pueden remitir
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    class="cebra elevation-2 mt-2"
                    :headers="headerss"
                    :items="detallesSeries"
                    :loading="looad"
                    dense
                    :search="searchh"
                  >
                    <!-- Buscador en el datatable -->
                    <template v-slot:top>
                      <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="6" sm="3">
                          <SearchDataTable
                            v-model="searchh"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        Aún no se seleccionaron archivos de series.
                      </v-alert>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
          <v-btn color="error" class="mr-2" @click="limpiarDetalles()" v-if="detalles.length > 0">Limpiar Detalles</v-btn>
          <v-btn color="success" class="mr-2" @click="confirmarCargaAsistida()" v-if="sonSeries == 0">Aceptar</v-btn>
          <BtnConfirmar 
            texto="Se agregarán al remito las series sin observaciones. ¿Confirma continuar?"
            nombre="Continuar"
            icono="fas fa-check"
            @action="confirmarSeries()"
            v-if="sonSeries == 1"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Seleccionar un Artículo -->
    <SeleccionarArticulo 
      :dialogActivo="dialogSelectArticulo"
      :detallesArts="selectArticulos"
      @setearModalSelectArt="setModalSelectArt"
      @seleccionarArt="setArtSeleccionado"
    />
  </div>
</template>

<script>
  import SearchDataTable from '../../util/SearchDataTable.vue'
  import SeleccionarArticulo from '../SeleccionarArticulo.vue'
  import { tipos_archivos } from '../../../util/utils'
  import BtnConfirmar from '../../util/BtnConfirmar.vue'
  import { read, utils } from 'xlsx'
export default {
  props: {
    datosModal: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    SeleccionarArticulo,
    BtnConfirmar
  },
  computed: {
    activo: {
      get() {
        return this.datosModal.dialogActivo
      },
      set(value) {
        this.$emit('setearModalCAsistida', value)
      }
    },
    bodegaOrigen: {
      get() {
        return this.datosModal.bodegaOrigen
      }
    },
    bodegaDestino: {
      get() {
        return this.datosModal.bodegaDestino
      }
    },
    tipoIngreso: {
      get() {
        return this.datosModal.tipoIngreso
      },
      set(value) {
        this.$emit('setearTipIngCAsistida', value)
      }
    },
    calcularTotales: {
      get() {
        return this.datosModal.mostrarTotales
      }
    },
    conArchivo: {
      get() {
        return this.datosModal.archivo
      }
    }
  },
  data() {
    return {
      articulo: '',
      headers: [
        { text: 'Artículo Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Código Barras', align: 'center', value: 'articulo_cod_barras' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Artículo Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Artículo Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Serie', align: 'center', value: 'serie' },
        { text: 'Observaciones.', align: 'left', value: 'estado' }
      ],
      detalles: [],
      detallesSeries: [],
      load: false,
      looad: false,
      search: '',
      searchh: '',
      detallesProcess: [],
      dialogSelectArticulo: false,
      selectArticulos: [],
      tiposValidos: ['.xls', '.xlsx', '.txt'],
      contenidoArchivo: [],
      seriesImportadas: [],
      sonSeries: 0,
      detallesSeriesResp: []
    }
  },
  methods: {
    conInconsistencias(){
      for (let id in this.detalles){
        if (this.detalles[id].solicita_serie == 1 || this.detalles[id].inexistente == 1 || this.detalles[id].repetido == 1 || this.detalles[id].sin_stock == 1 || 
        this.detalles[id].dif_stock == 1 || this.detalles[id].sin_precio == 1){
          return true
        }
      }
      return false
    },
    limpiarDetalles(){
      this.detalles = []
      // agregado por mmacoritto el 7/6/23 pq no limpia el local storage y cuando vuelvo a abrir trae todo de nuevo
      localStorage.setItem('remitoAsistido', JSON.stringify([]))
    },
    async agregarInput(){
      // escanearon por código de barras o de BB?
      if (this.articulo.toString().length > 0){
        // ingresa codigo de BB o de barras?
        let codBB = ''
        let codBarras = ''
        if (this.tipoIngreso == false){
          // es codigo de BB
          codBB = this.articulo
        }else{
          // es codigo de barras
          codBarras = this.articulo
        }
        // defino mi variable a agregar
        let art = this.armarArticulo(codBB, codBarras)
        // ya se encuentra en mi detalle?
        let unArt = []
        if (art.articulo_codigo.toString().length > 0){
          unArt = this.detalles.filter(element => element.articulo_codigo == art.articulo_codigo && element.articulo_codigo.toString().length > 0)
        }else{
          unArt = this.detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == art.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        }
        if (unArt.length > 0){
          // ya se encuentra, acumulo
          let pos = this.detalles.indexOf(unArt[0])
          this.detalles[pos].cantidad = parseInt(this.detalles[pos].cantidad) + 1
        }else{
          // no se encuentra, lo agrego
          this.detalles.push(art)
        }
        // guardo en localStorage
        this.guardarLocalStorage(art, 1)
        // volver el foco
        this.$refs.redArticulo.focus()
        // limpiar los campos para nuevo ingreso
        this.articulo = ''
      }
    },
    armarArticulo(codBB, codBarras){
      let art = {
        articulo_codigo: codBB,
        articulo_nombre: '',
        articulo_cod_barras: codBarras,
        categoria_codigo: '',
        solicita_serie: 0,
        cantidad: 1,
        inexistente: 0,
        repetido: 0,
        sin_stock: 0,
        dif_stock: 0,
        sin_precio: 0,
        series: []
      }
      return art
    },
    quitarArticulo(item){
      let pos = this.detalles.indexOf(item)
      this.detalles.splice(pos, 1)
      // guardo en localStorage
      this.guardarLocalStorage(item, 2)
      if (this.conArchivo == 0){
        // volver el foco
        this.$refs.redArticulo.focus()
      }
    },
    limpiarModal(){
      this.articulo = ''
      this.search = ''
      this.searchh = ''
      this.detalles = []
      this.selectArticulos = []
      this.detallesProcess = []
      this.contenidoArchivo = []
      this.seriesImportadas = []
      this.detallesSeries = []
      this.sonSeries = 0
      this.detallesSeriesResp = []
      this.tipoIngreso = false
    },
    guardarLocalStorage(item, opcion){
      let detalles = []
      if (localStorage.getItem('remitoAsistido')){
        detalles = JSON.parse(localStorage.getItem('remitoAsistido'))
      }
      if (opcion == 1){
        // agregar, existe en el listado?
        if (detalles.length == 0){
          detalles.push(item)
        }else{
          let unArtCodBB = detalles.filter(element => element.articulo_codigo == item.articulo_codigo && element.articulo_codigo.toString().length > 0)
          let unArtCodBarras = detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          if (unArtCodBB.length > 0 || unArtCodBarras.length > 0){
            if (unArtCodBB.length > 0){
              let pos = detalles.indexOf(unArtCodBB[0])
              detalles[pos].cantidad = parseInt(detalles[pos].cantidad) + 1
            }
            if (unArtCodBarras.length > 0){
              let pos = detalles.indexOf(unArtCodBarras[0])
              detalles[pos].cantidad = parseInt(detalles[pos].cantidad) + 1
            }
          }else{
            detalles.push(item)
          }
        }
      }else if(opcion == 2){
        // quitar
        /*
        let pos = detalles.indexOf(item)
        detalles.splice(pos, 1)
        */
       // agregado por mmacoritto el 7/6/23 pq anteriormente no lo encontraba y la pos siempre era -1
        let unArtBB = detalles.filter(element => element.articulo_codigo == item.articulo_codigo && element.articulo_codigo.toString().length > 0)
        let unArtCodBarras = detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (unArtBB.length > 0 || unArtCodBarras.length > 0){
          let pos = -1
          if (unArtBB.length > 0) {
            pos = detalles.indexOf(unArtBB[0])
          } else {
            pos = detalles.indexOf(unArtCodBarras[0])
          }
          if (pos != -1) {
            detalles.splice(pos, 1)
          }
        }
      }else if (opcion == 3){
        // cambiaron la cantidad, igualo la cantidad a la que eligio el usuario
        let unArtBB = detalles.filter(element => element.articulo_codigo == item.articulo_codigo && element.articulo_codigo.toString().length > 0)
        let unArtCodBarras = detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (unArtBB.length > 0 || unArtCodBarras.length > 0){
          let pos = -1
          if (unArtBB.length > 0){
            pos = detalles.indexOf(unArtBB[0])
            detalles[pos].cantidad = parseInt(item.cantidad)
          }else{
            pos = detalles.indexOf(unArtCodBarras[0])
          }
          if (pos != -1){
            detalles[pos].cantidad = parseInt(item.cantidad)
          }
        }
      }else if (opcion == 4){
        // quito todos los articulos del array de localStorage que contengan el codigo de barras y agrego solo con codigo de articulo y la cantidad totalizada
        if (detalles.length > 0){
          let art = this.armarArticulo(item.articulo_codigo, '')
          // quitar detalles con el codigo de barras duplicado
          for (let id in detalles){
            if (detalles[id].articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && detalles[id].articulo_cod_barras.toString().length > 0){
              detalles.splice(id, 1)
            }
          }
          // agregar o bien sumar cantidades
          let unArt = detalles.filter(element => element.articulo_codigo == art.articulo_codigo && element.articulo_codigo.toString().length > 0)
          if (unArt.length > 0){
            // existe, acumulo cantidad
            let pos = detalles.indexOf(unArt[0])
            detalles[pos].cantidad = parseInt(detalles[pos].cantidad) + 1
          }else{
            // no existe
            detalles.push(art)
          }
        }
      }else{
        // idem a opcion == 1 pero agregando la cantidad que viene en el item
        // existe en el listado?
        if (detalles.length == 0){
          detalles.push(item)
        }else{
          let unArtCodBB = detalles.filter(element => element.articulo_codigo == item.articulo_codigo && element.articulo_codigo.toString().length > 0)
          let unArtCodBarras = detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          if (unArtCodBB.length > 0 || unArtCodBarras.length > 0){
            if (unArtCodBB.length > 0){
              let pos = detalles.indexOf(unArtCodBB[0])
              detalles[pos].cantidad = parseInt(detalles[pos].cantidad) + parseInt(item.cantidad)
            }
            if (unArtCodBarras.length > 0){
              let pos = detalles.indexOf(unArtCodBarras[0])
              detalles[pos].cantidad = parseInt(detalles[pos].cantidad) + parseInt(item.cantidad)
            }
          }else{
            detalles.push(item)
          }
        }
      }
      
      localStorage.setItem('remitoAsistido', JSON.stringify(detalles))
    },
    async confirmarCargaAsistida(){
      // tengo algo para agregar?
      if (this.detalles.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin artículos para agregar.',
          color: 'info',
        })
        return
      }
      // existen inconsistencias?
      if (this.conInconsistencias()){
        this.$store.dispatch('show_snackbar', {
          text: 'Resuelva todas las inconsistencias antes de volver a confirmar.',
          color: 'info',
        })
        return
      }
      // PENDIENTE
      // aqui tengo que verificar que:
      // 1ro: no tenga articulos que soliciten series, en caso de ser asi, marcarlos para que el usuario los quite
      // 2do: si llegara a tener detalles con código de barras, busco el código de artículo relacionado. Si llegara a tener duplicados, los marco para que el usuario escoja cúal es el correcto
      // 3ro: marcar cúales son los artículos que no poseen stock para que el usuario los quite
      this.$store.state.loading = true
      let body = {
        bodega_origen: this.bodegaOrigen,
        bodega_destino: this.bodegaDestino,
        detalles: this.detalles,
        calcularTotales: this.calcularTotales
      }
      let controlPeticion = await this.$store.dispatch('stock/validateCargaAsistida', body)
      this.$store.state.loading = false
      if (controlPeticion.resultado == 1){
        this.detallesProcess = controlPeticion.articulos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: controlPeticion.msj,
          color: 'error',
        })
      }
    },
    marcarInexistentes(){
      for (let id in this.detalles){
        // por cod de BB
        let porCodBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        // por cod de barras
        let porCodBarra = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (porCodBB.length == 0 && porCodBarra.length == 0){
          this.detalles[id].inexistente = 1
        }
      }
    },
    marcarRepetidos(){
      // es decir, codigos de barras que estan asociados a mas de un código de BB, me quedo unicamente con los que tengan codigos de barra
      let conCodBarras = this.detalles.filter(element => element.articulo_cod_barras.toString().length > 0)
      if (conCodBarras.length > 0){
        for (let id in conCodBarras){
          let listCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == conCodBarras[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          if (listCodBarras.length > 1){
            let pos = this.detalles.indexOf(conCodBarras[id])
            this.detalles[pos].repetido = 1
          }
        }
      }
    },
    marcarSolicitanSerie(){
      for (let id in this.detallesProcess){
        if (this.detallesProcess[id].solicita_serie == 1){
          // obtengo todos los articulos para marcarlos como solicitan serie
          let conCodBB = this.detalles.filter(element => element.articulo_codigo == this.detallesProcess[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
          let conCodBarras = this.detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detallesProcess[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          if (conCodBB.length > 0){
            let pos = this.detalles.indexOf(conCodBB[0])
            this.detalles[pos].solicita_serie = 1
          }
          if (conCodBarras.length > 0){
            let pos = this.detalles.indexOf(conCodBarras[0])
            this.detalles[pos].solicita_serie = 1
          }
        }
      }
    },
    mostrarMsjInexistente(item){
      let articulo = ''
      if (item.articulo_cod_barras.toString().length > 0){
        articulo = item.articulo_cod_barras
      }else{
        articulo = item.articulo_codigo
      }
      this.$store.dispatch('show_snackbar', {
        text: 'El artículo ' + articulo + ' no existe en la base de datos. Proceda a quitarlo del detalle y vuelva a confirmar.',
        color: 'info',
      })
    },
    mostrarMsjSolicitaSerie(item){
      this.$store.dispatch('show_snackbar', {
        text: 'No se admiten artículos que requieran series en la carga Asistida. Proceda a quitarlo del detalle y vuelva a confirmar.',
        color: 'info',
      })
    },
    resolverRepetidos(item){
      // obtengo los articulos repetidos
      let repetidos = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
      if (repetidos.length > 0){
        for (let id in repetidos){
          repetidos[id].codigo_barras = repetidos[id].articulo_cod_barras
        }
        this.selectArticulos = repetidos
        this.dialogSelectArticulo = true
      }
    },
    mostrarMsjNoStock(item){
      let articulo = ''
      if (item.articulo_cod_barras.toString().length > 0){
        articulo = item.articulo_cod_barras
      }else{
        articulo = item.articulo_codigo
      }
      this.$store.dispatch('show_snackbar', {
        text: 'El artículo ' + articulo + ' no cuenta con stock disponible para remitir. Proceda a quitarlo del detalle y vuelva a confirmar.',
        color: 'info',
      })
    },
    resolverStockInsuficiente(item){
      let articulo = ''
      if (item.articulo_cod_barras.toString().length > 0){
        articulo = item.articulo_cod_barras
      }else{
        articulo = item.articulo_codigo
      }
      // obtengo su cantidad disponible
      let artBB = this.detallesProcess.filter(element => element.articulo_codigo == item.articulo_codigo && element.articulo_codigo.toString().length > 0)
      let artCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
      if (artBB.length > 0 || artCodBarras.length > 0){
        if (artBB.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El artículo ' + articulo + ' no cuenta con ' + item.cantidad + ' unidades. Pruebe con ' + artBB[0].cant_disponible + ' unidades y vuelva a confirmar.',
            color: 'info',
          })
          return
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'El artículo ' + articulo + ' no cuenta con ' + item.cantidad + ' unidades. Pruebe con ' + artCodBarras[0].cant_disponible + ' unidades y vuelva a confirmar.',
            color: 'info',
          })
          return
        }
      }
      this.$store.dispatch('show_snackbar', {
        text: 'El artículo ' + articulo + ' no cuenta con ' + item.cantidad + ' unidades. Pruebe con ' + item.cant_disponible + ' unidades y vuelva a confirmar.',
        color: 'info',
      })
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo = value
    },
    setArtSeleccionado(item){
      // al seleccionar a qué artículo pertenece, le quito el código de barras para que en la nueva consulta no vuelva a obtener duplicados
      // obtengo la posicion
      let unArt = this.detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == item.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
      if (unArt.length > 0){
        let pos = this.detalles.indexOf(unArt[0])
        // asigno los valores
        this.detalles[pos].articulo_codigo = item.articulo_codigo
        this.detalles[pos].articulo_cod_barras = ''
        this.detalles[pos].repetido = 0
        // estaba marcado como que no tenia stock?
        if (this.detalles[pos].sin_stock == 1){
          if (parseInt(item.cant_disponible) > 0){
            this.detalles[pos].sin_stock = 0
          }
        }
        // estaba marcado como que no tenia stock suficiente?
        if (this.detalles[pos].dif_stock == 1){
          if (parseInt(item.cant_disponible) >= parseInt(item.cantidad) && parseInt(item.cant_disponible) > 0){
            this.detalles[pos].dif_stock == 0
          }
        }
        // verifico que no me queden duplicados
        this.validarDuplicados()
        // arreglo localStorage
        this.guardarLocalStorage(item, 4)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo resolver la inconsistencia. Pruebe quitar el artículo e ingresarlo con código de BB.',
          color: 'warning',
        })
      }
    },
    validarDuplicados(){
      // al resolver la duplicidad de un artículo, puede quedar repetido en el datatable. En caso de ser asi, sumo las cantidades de cada detalle y lo dejo totalizado en 1 detalle
      for (let id in this.detalles){
        let unosArt = this.detalles.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        // valido si tengo repetidos
        if (unosArt.length > 1){
          // quito los detalles y acumulo las cantidades
          let cant = 0
          for (let jd in unosArt){
            let pos = this.detalles.indexOf(unosArt[jd])
            this.detalles.splice(pos, 1)
            cant = parseInt(cant) + parseInt(unosArt[jd].cantidad)
          }
          // defino mi variable a agregar
          let art = this.armarArticulo(unosArt[0].articulo_codigo, '')
          // asigno la cantidad acumulada
          art.cantidad = cant
          this.detalles.push(art)
        }
      }
    },
    marcarSinStock(){
      // marcar los detalles que tengan cantidad disponible en 0
      for (let id in this.detalles){
        let unArtBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        let unArtCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (unArtBB.length > 0 || unArtCodBarras.length > 0){
          if (unArtBB.length > 0){
            if (parseInt(unArtBB[0].cant_disponible) == 0){
              this.detalles[id].sin_stock = 1
            }
          }else{
            for (let jd in unArtCodBarras){
              if (parseInt(unArtCodBarras[jd].cant_disponible) == 0){
                this.detalles[id].sin_stock = 1
                return
              }
            }
          }
        }
      }
    },
    marcarSinStockSuficiente(){
      // quiere decir que para algun detalle tengo una cantidad disponible menor a la que solicitan, pero cant_disponible mayor a cero
      for (let id in this.detalles){
        let unArtBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        let unArtCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (unArtBB.length > 0 || unArtCodBarras.length > 0){
          if (unArtBB.length > 0){
            if (parseInt(unArtBB[0].cant_disponible) < parseInt(unArtBB[0].cantidad) && parseInt(unArtBB[0].cant_disponible) > 0){
              this.detalles[id].dif_stock = 1
            }
          }else{
            for (let jd in unArtCodBarras){
              if (parseInt(unArtCodBarras[jd].cant_disponible) < parseInt(unArtCodBarras[jd].cantidad) && parseInt(unArtCodBarras[jd].cant_disponible) > 0){
                this.detalles[id].dif_stock = 1
              }
            }
          }
        }
      }
    },
    // agregado por mmacoritto el 7/6/23 para que no deje agregar articulos sin precio en bodegas de salon
    marcarSinPrecio () {
      // marcar los detalles que tengan sin_precio en 1
      for (let id in this.detalles){
        let unArtBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        let unArtCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (unArtBB.length > 0 || unArtCodBarras.length > 0){
          if (unArtBB.length > 0){
            if (parseInt(unArtBB[0].sin_precio) == 1){
              this.detalles[id].sin_precio = 1
            }
          }else{
            for (let jd in unArtCodBarras){
              if (parseInt(unArtCodBarras[jd].sin_precio) == 1){
                this.detalles[id].sin_precio = 1
                //return
              }
            }
          }
        }
      }
    },
    mostrarMsjSinPrecio (item) {
      let articulo = ''
      if (item.articulo_cod_barras.toString().length > 0){
        articulo = item.articulo_cod_barras
      } else {
        articulo = item.articulo_codigo
      }
      this.$store.dispatch('show_snackbar', {
        text: 'El artículo ' + articulo + ' no cuenta con un precio definido en la lista de la bodega destino. Proceda a quitarlo del detalle y vuelva a confirmar.',
        color: 'info'
      })
    },
    quitarStockInsuficiente(item){
      // agregado por mmacoritto el 7/6/23 pq permitia ingresar negativos y con 0 se bugeaba
      if (item.cantidad <= 0) {
        item.cantidad = null
        return this.$store.dispatch('show_snackbar', {
          text: 'La cantidad debe ser mayor que 0',
          color: 'orange'
        })
      }
      let pos = this.detalles.indexOf(item)
      this.detalles[pos].dif_stock = 0
      this.guardarLocalStorage(item, 3)
    },
    validarInconsistencias(){
      for (let id in this.detallesProcess){
        // solicitan serie?
        if (this.detallesProcess[id].solicita_serie == 1){
          return true
        }
      }
      for (let id in this.detalles){
        let porCodBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        let porCodBarra = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        // inexistente?
        if (porCodBB.length == 0 && porCodBarra.length == 0){
          return true
        }
        if (porCodBB.length > 0 || porCodBarra.length > 0){
          if (porCodBB.length > 0){
            // sin stock?
            if (parseInt(porCodBB[0].cant_disponible) == 0){
              return true
            }
            // sin stock suficiente?
            if (parseInt(porCodBB[0].cant_disponible) < parseInt(porCodBB[0].cantidad) && parseInt(porCodBB[0].cant_disponible) > 0){
              return true
            }
            // sin precio?
            if (parseInt(porCodBB[0].sin_precio) == 1){
              return true
            }
          }else{
            for (let jd in porCodBarra){
              // sin stock?
              if (parseInt(porCodBarra[jd].cant_disponible) == 0){
                return true
              }
              // sin stock suficiente?
              if (parseInt(porCodBarra[jd].cant_disponible) < parseInt(porCodBarra[jd].cantidad) && parseInt(porCodBarra[jd].cant_disponible) > 0){
                return true
              }
              // sin precio?
              if (parseInt(porCodBarra[0].sin_precio) == 1){
                return true
              }
            }
          }
        }
      }
      // repetidos?
      let conCodBarras = this.detalles.filter(element => element.articulo_cod_barras.toString().length > 0)
      if (conCodBarras.length > 0){
        for (let id in conCodBarras){
          let listCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == conCodBarras[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          if (listCodBarras.length > 1){
            return true
          }
        }
      }
      return false
    },
    convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    },
    responderDetalles(){
      // salida del modal
      let listadoSalida = []
      // recorro mis detalles
      for (let id in this.detalles){
        // obtengo los datos de la base de datos
        let codBarras= ''
        let codArticulo = ''
        let nomArticulo = ''
        let precioArt = 0
        let artBB = this.detallesProcess.filter(element => element.articulo_codigo == this.detalles[id].articulo_codigo && element.articulo_codigo.toString().length > 0)
        let artCodBarras = this.detallesProcess.filter(element => element.articulo_cod_barras.toString().toUpperCase() == this.detalles[id].articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
        if (artBB.length > 0){
          codArticulo = artBB[0].articulo_codigo
          nomArticulo = artBB[0].articulo_nombre
          precioArt = this.convertDecimals(artBB[0].precio)
          if (artBB[0].articulo_cod_barras.toString().length > 0){
            codBarras = artBB[0].articulo_cod_barras
          }
        }
        if (artCodBarras.length > 0){
          codArticulo = artCodBarras[0].articulo_codigo
          nomArticulo = artCodBarras[0].articulo_nombre
          precioArt = this.convertDecimals(artCodBarras[0].precio)
          codBarras = artCodBarras[0].articulo_cod_barras
        }
        // defino el objeto a responder
        let art = {
          articulo_cod_barras: codBarras,
          articulo_codigo: codArticulo,
          articulo_nombre: nomArticulo,
          cantidad: parseInt(this.detalles[id].cantidad),
          cantidadAnt: parseInt(this.detalles[id].cantidad),
          importe: this.convertDecimals(precioArt * parseInt(this.detalles[id].cantidad)),
          precio: precioArt,
          series: [],
          solicita_serie: 0,
          tipo: 2,
          reagrupado: 0,
          validado: 1
        }
        // ya existe en el listado?
        let existeLis = listadoSalida.filter(element => element.articulo_codigo == art.articulo_codigo)
        if (existeLis.length > 0){
          // recalculo la cantidad y el importe
          let pos = listadoSalida.indexOf(existeLis[0])
          let cantAgregar = parseInt(listadoSalida[pos].cantidad) + parseInt(existeLis[0].cantidad)
          listadoSalida[pos].cantidad = cantAgregar
          listadoSalida[pos].cantidadAnt = cantAgregar
          listadoSalida[pos].importe = this.convertDecimals(listadoSalida[pos].precio * cantAgregar)
          listadoSalida[pos].reagrupado = 1
          listadoSalida[pos].validado = 0
        }else{
          listadoSalida.push(art)
        }
      }
      // limpio localStorage
      localStorage.setItem('remitoAsistido', JSON.stringify([]))
      // marco si respondo los detalles usando archivo o carga asistida
      if (this.conArchivo == 1){
        // devuelvo el listado
        this.$emit('setDetallesRemito', {listadoSalida: listadoSalida, acumular: 1})
      }else{
        // devuelvo el listado
        this.$emit('setDetallesRemito', {listadoSalida: listadoSalida, acumular: 0})
      }
      // cierro el modal
      this.activo = false
    },
    to_json (file, opcion) {
      try {
        this.contenidoArchivo = []
        const reader = new FileReader()
        reader.onload = (e) => {
          const bytes = e.target.result
          const excel = read(bytes, { type: 'binary', cellDates: true })
          // obtiene el nombre de la primer hoja
          const hoja_nombre = excel.SheetNames[0]
          // obtiene la primer hoja
          const hoja = excel.Sheets[hoja_nombre]
          // obtiene la data de la primer hoja
          const data = utils.sheet_to_json(hoja, { header: 1 })
          // cicla por cada row de la primer hoja (excluyendo los headers)
          this.json = []
          for (let i = 1; i < data.length; i++){
            const row = data[i]
            if (row.length > 0){
              let unaRow = {}
              if (row[1] != undefined && row[1] != null && row[1] != '' && row[1] != 'null'){
                /*if (row[0] != undefined && row[0] != null && row[0] != '' && row[0] != 'null'){
                  // por codigo de barras
                  unaRow = {
                    articulo_codigo: '',
                    articulo_cod_barras: row[0],
                    series: [],
                    cantidad: row[1]
                  }
                }*/
                if (opcion == 2){
                  if (row[0] != undefined && row[0] != null && row[0] != '' && row[0] != 'null'){
                    // por codigo de articulo
                    unaRow = {
                      articulo_codigo: row[0],
                      articulo_cod_barras: '',
                      series: [],
                      cantidad: row[1]
                    }
                  }
                }else{
                  if (row[0] != undefined && row[0] != null && row[0] != '' && row[0] != 'null'){
                    // por codigo de barras
                    unaRow = {
                      articulo_codigo: '',
                      articulo_cod_barras: row[0],
                      series: [],
                      cantidad: row[1]
                    }
                  }
                }
                if (Object.keys(unaRow).length > 0){
                  this.contenidoArchivo.push(unaRow)
                }
              }
            }
          }
          this.cargarDetalles()
        }
        reader.readAsBinaryString(file)
      } catch (error) {
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
          color: 'error',
          timeout: 4000
        })
      }
    },
    to_jsonTxt(file){
      let reader = new FileReader()
      let data = ''
      this.seriesImportadas = []
      this.detallesSeries = []
      this.detallesSeriesResp = []
      reader.onload = (e) => {
        const bytes = e.target.result
        if (bytes) {
          data = bytes
          data = data.split('\n')
          // ya esta en mi listado?
          if (data.length > 0){
            for (let id in data){
              this.seriesImportadas.push(data[id].toString().trim())
            }
            this.getSeriesInfo()
          }else{
            this.$store.state.loading = false
          }
        }else{
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Archivo sin datos',
            color: 'warning'
          })
        }
      }
      reader.readAsText(file)
    },
    buscar(event){
      this.$store.state.loading = true
      let files = event.target.files
      // paso a validar los tipos de archivos que me mandaron
      let file = files[0]
      let type = file.type
      let tipoArchivo = tipos_archivos.find(a => a.mime == type)
      if (Object.keys(tipoArchivo).length > 0){
        // es extensión permitida?
        let extPermitida = this.tiposValidos.filter(element => element == tipoArchivo.extension)
        if (extPermitida.length > 0){
          // es un txt lo que estan ingresando?
          this.sonSeries = 0
          if (tipoArchivo.extension == '.txt'){
            // lo marco como que estan ingresando series
            this.sonSeries = 1
          }
          if (this.sonSeries == 0){
            // 06-06-2023 por MMURILLO, armamos el datatable de acuerdo al tipo de ingreso
            if (this.tipoIngreso == false) this.to_json(file, 2)
            else this.to_json(file, 1)
          }else{
            this.to_jsonTxt(file)
          }
        }else{
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
        }
      }else{
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
      }
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    cargarDetalles(){
      if (this.contenidoArchivo.length > 0){
        for (let id in this.contenidoArchivo){
          // armo mi articulo
          let art = this.armarArticulo(this.contenidoArchivo[id].articulo_codigo, this.contenidoArchivo[id].articulo_cod_barras)
          // asigno la cantidad
          art.cantidad = parseInt(this.contenidoArchivo[id].cantidad)
          // ya se encuentra en mi detalle?
          let unArt = []
          if (art.articulo_codigo.toString().length > 0){
            unArt = this.detalles.filter(element => element.articulo_codigo == art.articulo_codigo && element.articulo_codigo.toString().length > 0)
          }else{
            unArt = this.detalles.filter(element => element.articulo_cod_barras.toString().toUpperCase() == art.articulo_cod_barras.toString().toUpperCase() && element.articulo_cod_barras.toString().length > 0)
          }
          if (unArt.length > 0){
            // ya se encuentra, acumulo
            let pos = this.detalles.indexOf(unArt[0])
            this.detalles[pos].cantidad = parseInt(this.detalles[pos].cantidad) + parseInt(art.cantidad)
          }else{
            // no se encuentra, lo agrego
            this.detalles.push(art)
          }
          this.guardarLocalStorage(art, 5)
        }
      }
      // cierro el modal de carga
      this.$store.state.loading = false
      // reseteo el array de articulos
      this.contenidoArchivo = []
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    async getSeriesInfo(){
      let infoPeticion = await this.$store.dispatch('stock/getSeriesInfo', {series: this.seriesImportadas, bodega_origen: this.bodegaOrigen, bodega_destino: this.bodegaDestino, calcularTotales: this.calcularTotales})
      this.$store.state.loading = false
      if (infoPeticion.resultado == 1){
        this.detallesSeriesResp = infoPeticion.articulos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: infoPeticion.msj,
          color: 'error'
        })
      }
    },
    msjNoRemitir(item){
      this.$store.dispatch('show_snackbar', {
        text: item.estado,
        color: 'info'
      })
    },
    verifyInconsistencias(){
      let esInconsistente = 0
      for (let id in this.detallesSeriesResp){
        let inconsistente = this.detallesSeriesResp[id].series.filter(element => element.estado.valido == 0)
        if (inconsistente.length > 0){
          esInconsistente = 1
          for (let jd in inconsistente){
            let unArt = {
              articulo_codigo: inconsistente[jd].articulo_codigo,
              articulo_nombre: this.detallesSeriesResp[id].articulo_nombre,
              serie: inconsistente[jd].serie,
              estado: inconsistente[jd].estado.msj,
              inconsistente: 1
            }
            this.detallesSeries.push(unArt)
          }
        }
      }
      return esInconsistente
    },
    confirmarSeries(){
      // salida del modal
      let listadoSalida = []
      for (let id in this.detallesSeriesResp){
        // defino el objeto a responder
        let art = {
          articulo_cod_barras: '',
          articulo_codigo: this.detallesSeriesResp[id].articulo_codigo,
          articulo_nombre: this.detallesSeriesResp[id].articulo_nombre,
          cantidad: 0,
          cantidadAnt: 0,
          importe: 0,
          precio: this.detallesSeriesResp[id].precio,
          series: [],
          solicita_serie: 1,
          tipo: 2,
          reagrupado: 0,
          validado: 1
        }
        // depuro las series, me quedo solo con las que no tienen observaciones y recalculo la cantidad
        for (let jd in this.detallesSeriesResp[id].series){
          if (this.detallesSeriesResp[id].series[jd].estado.valido == 0){
            this.detallesSeriesResp[id].series.splice(jd, 1)
          }
        }
        // verifico con cuantas me quedé
        let cantidad = this.detallesSeriesResp[id].series.length
        // para remitir necesito tener además cantidad disponible mayor a cero
        if (parseInt(cantidad) > 0 && parseInt(this.detallesSeriesResp[id].cant_disponible) > 0){
          // puedo remitir la cantidad obtenida?
          if (parseInt(this.detallesSeriesResp[id].cant_disponible) >= parseInt(cantidad)){
            // puedo remitir todas las series que obtuve
            art.cantidad = parseInt(cantidad)
            art.cantidadAnt = parseInt(cantidad)
            art.importe = this.convertDecimals(art.precio * parseInt(cantidad))
            art.series = this.detallesSeriesResp[id].series
          }else{
            // puedo remitir solo unas cuantas, las elijo yo
            while (this.detallesSeriesResp[id].series.length > parseInt(this.detallesSeriesResp[id].cant_disponible)){
              // quito siempre el de la primera posicion
              this.detallesSeriesResp[id].series.splice(0, 1)
            }
            // asigno valores
            art.cantidad = parseInt(this.detallesSeriesResp[id].cant_disponible)
            art.cantidadAnt = parseInt(this.detallesSeriesResp[id].cant_disponible)
            art.importe = this.convertDecimals(art.precio * parseInt(this.detallesSeriesResp[id].cant_disponible))
            art.series = this.detallesSeriesResp[id].series
          }
          listadoSalida.push(art)
        }
      }
      // marco si respondo los detalles usando archivo o carga asistida
      this.$emit('setDetallesRemito', {listadoSalida: listadoSalida, acumular: 1})
      // cierro el modal
      this.activo = false
    }
  },
  watch: {
    tipoIngreso: function(){
      if (this.conArchivo == 0){
        this.$refs.redArticulo.focus()
        this.articulo = ''
      }
    },
    activo: function(){
      if (this.activo == false){
        this.limpiarModal()
      }else{
        // iniciar data table
        if (this.conArchivo == 0){
          if (localStorage.getItem('remitoAsistido')){
            this.detalles = JSON.parse(localStorage.getItem('remitoAsistido'))
          }
        }
      }
    },
    detallesProcess: function(){
      if (this.detallesProcess.length > 0){
        for (let id in this.detallesProcess){
          if (this.detallesProcess[id].articulo_cod_barras == null || this.detallesProcess[id].articulo_cod_barras == undefined || this.detallesProcess[id].articulo_cod_barras == 'null'){
            this.detallesProcess[id].articulo_cod_barras = ''
          }
          if (this.detallesProcess[id].codigo_ean == null || this.detallesProcess[id].codigo_ean == undefined || this.detallesProcess[id].codigo_ean == 'null') this.detallesProcess[id].codigo_ean = ''
        }
        // voy a tener incosistencias?
        if (!this.validarInconsistencias()){
          this.responderDetalles()
        }else{
          // marcar articulos inexistentes
          this.marcarInexistentes()
          // marcar repetidos
          this.marcarRepetidos()
          // marcar que solicitan serie
          this.marcarSolicitanSerie()
          // marcar los que no tienen stock
          this.marcarSinStock()
          // marcar con diferencia de stock
          this.marcarSinStockSuficiente()
          // marcar con diferencia de stock
          this.marcarSinPrecio()
        }
      }else{
        if (this.detalles.length > 0){
          // marcar articulos inexistentes
          this.marcarInexistentes()
          // marcar los que no tienen stock
          this.marcarSinStock()
          // marcar con diferencia de stock
          this.marcarSinStockSuficiente()
          // marcar con diferencia de stock
          this.marcarSinPrecio()
        }
      }
    },
    detallesSeriesResp: function(){
      if (this.detallesSeriesResp.length > 0){
        this.detallesSeries = []
        let inconsistente = this.verifyInconsistencias()
        if (inconsistente == 0){
          // respondo al form nuevo remito
          this.confirmarSeries()
        }
      }
    }
  },
}
</script>

<style>

</style>