<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <div v-if="nuevaPromo == 1">Nueva Promoción</div>
        <div v-else>Ver Promoción Nro {{ $route.params.id }}</div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
            v-if="nuevaPromo == 0"
            :disabled="tienePermiso == 0 && selectedEstado.estado_id != 2"
          ></v-switch>
        </v-expand-x-transition>
      </v-col>
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Descripción, Vigente y Estado -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" :sm="nuevaPromo == 1 ? 11 : 10" :md="nuevaPromo == 1 ? 11 : 10" class="py-1">
                <v-text-field
                  v-model="descripcion"
                  outlined
                  dense
                  type="text"
                  hide-details
                  label="Descripción"
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="py-1" v-if="nuevaPromo == 1">
                <v-checkbox
                  v-model="vigente"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  dense
                  label="Vigente"
                  :disabled="tienePermiso == 0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1" v-if="nuevaPromo == 0">
                <v-autocomplete
                  v-model="selectedEstado"
                  item-text="estado_nombre"
                  :items="estados"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Estado"
                  :disabled="editar == false || tienePermiso == 0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Descuento, Fechas y Cupones -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-3">
              <v-col cols="12" sm="4" md="4" class="py-1">
                <v-text-field
                  v-model="descuento"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Descuento"
                  prefix="%"
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <FechaPicker
                    v-model="fechaInicio"
                    label="Fecha Inicio"
                    :disabled="editar == false && nuevaPromo == 0"
                  />
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <FechaPicker
                    v-model="fechaFin"
                    label="Fecha Fin"
                    :disabled="editar == false && nuevaPromo == 0"
                  />
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <div class="d-flex justify-center">
                  <v-checkbox
                    v-model="conCupones"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                    label="Cupones"
                    :disabled="nuevaPromo == 0 && nuevaPromo == 0"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <v-text-field
                  v-model="cantCupones"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Cant. Cupones"
                  :readonly="conCupones == 0 || nuevaPromo == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Cantidad min y max, Importe min y max -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-0 mt-0">
              <v-col cols="12" sm="3" md="3" class="py-1">
                <v-text-field
                  v-model="cantMin"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Cantidad Min."
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-1">
                <v-text-field
                  v-model="cantMax"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Cantidad Max."
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-1">
                <v-text-field
                  v-model="importeMin"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Importe Min."
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-1">
                <v-text-field
                  v-model="importeMax"
                  outlined
                  dense
                  type="number"
                  hide-details
                  label="Importe Max."
                  :readonly="editar == false && nuevaPromo == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Empresas y Canales -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedEmpresa"
                  item-text="nombre"
                  :items="empresas"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Empresas"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedCanal"
                  item-text="canal_nombre"
                  :items="canales"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Canales"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Sucursales y Locales -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedSucursal"
                  item-text="nombre"
                  :items="sucursales"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Sucursales"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedLocal"
                  item-text="local_nombre"
                  :items="locales"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Locales"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Dias y Categorias -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedDia"
                  item-text="dia_nombre"
                  :items="dias"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Dias"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-1">
                <v-autocomplete
                  v-model="selectedCategoria"
                  item-text="categoria_nombre"
                  :items="categorias"
                  hide-details
                  outlined
                  dense
                  return-object
                  label="Categorias"
                  multiple
                  deletable-chips
                  small-chips
                  :disabled="editar == false && nuevaPromo == 0"
                  clearable
                  @change="controlTodasCat()"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Artículos -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                <v-card-title>
                  Artículos
                  <v-btn icon @click="getTodosArticulos(1)" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <!-- Agregar Artículo -->
                  <v-row v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                    <!-- <v-col cols="12" sm="2" md="2" class="py-1" v-if="permisoBono == 1">
                      <v-text-field
                        v-model="bonoProm"
                        outlined
                        dense
                        type="number"
                        label="Bono"
                        hide-details
                        prefix="%"
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="2" md="2" class="py-1">
                      <v-text-field
                        v-model="artCodigo"
                        :id="artRef"
                        :ref="artRef"
                        outlined
                        dense
                        hide-details
                        type="number"
                        label="Cod. Artículo"
                        @blur="addArticulo({}, 0)"
                        @keypress.native.enter="$refs[artRef].blur()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-1">
                      <v-text-field
                        v-model="artNombre"
                        outlined
                        dense
                        type="text"
                        label="Nombre"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                      <v-btn 
                        color="primary" 
                        title="Agregar Artículo"
                        @click.stop="addArticulo({}, 0)"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Listado de Artículos -->
                  <v-data-table
                    class="cebra elevation-2 mt-2"
                    :headers="permisoBono == 0 ? headers : headers2"
                    :items="articulos"
                    :loading="load"
                    dense
                    :search="search"
                  >
                    <!-- Buscador en el datatable -->
                    <template v-slot:top>
                      <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="6" sm="3">
                          <SearchDataTable
                            v-model="search"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        No se agregaron artículos.
                      </v-alert>
                    </template>
                    <!-- Items -->
                    <template v-slot:[`item.bono`]="{ item }" v-if="(nuevaPromo == 1 && permisoBono == 1) || (nuevaPromo == 0 && permisoBono == 1)">
                      <v-row class="d-flex justify-center" no-gutters>
                        <v-text-field 
                          :id="reconRef"
                          :ref="reconRef"
                          v-model="item.bono" 
                          type="number"
                          outlined 
                          dense 
                          hide-details 
                          style="max-width: 80px"
                          :readonly="(nuevaPromo == 0 && editar == false) || (permisoBono == 0 && nuevaPromo == 0)"
                          prefix="%"
                          @blur="validarPorcentaje(item)"
                          @keypress.native.enter="$refs[reconRef].blur()"
                        ></v-text-field>
                      </v-row>
                    </template>
                    <!-- Acciones -->
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="error" @click="quitarArticulo(item)" v-on="on">
                            <v-icon small>far fa-times-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- Artículos Exceptuados -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-4">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Artículos Exceptuados
                  <v-btn icon @click="getTodosArticulos(2)" v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>
                    <!-- Agregar Artículo -->
                    <v-row v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                      <v-col cols="12" sm="2" md="2" class="py-1">
                      <v-text-field
                        v-model="artCodigoExcep"
                        :id="artRefExcep"
                        :ref="artRefExcep"
                        outlined
                        dense
                        type="number"
                        label="Cod. Artículo"
                        @blur="addArticuloExcep({}, 0)"
                        @keypress.native.enter="$refs[artRefExcep].blur()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-1">
                      <v-text-field
                        v-model="artNombreExcep"
                        outlined
                        dense
                        type="text"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                      <v-btn 
                        color="primary" 
                        title="Agregar Artículo"
                        @click.stop="addArticuloExcep({}, 0)"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                    </v-row>
                    <!-- Listado de Artículos Exceptuados -->
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="headerss"
                      :items="articulosExcep"
                      :loading="loadd"
                      dense
                      :search="searchh"
                    >
                      <!-- Buscador en el datatable -->
                      <template v-slot:top>
                        <v-row class="d-flex justify-end pa-2" no-gutters>
                          <v-col cols="6" sm="3">
                            <SearchDataTable
                              v-model="searchh"
                            />
                          </v-col>
                        </v-row>
                      </template>
                      <!-- Msj que se mostrara si no existen resultados -->
                      <template v-slot:no-data>
                        <v-alert
                          class="mx-auto mt-4"
                          max-width="400"
                          type="warning"
                          border="left"
                          dense
                          text
                        >
                          No se agregaron artículos.
                        </v-alert>
                      </template>
                      <!-- Acciones -->
                      <template v-slot:[`item.acciones`]="{ item }">
                        <v-tooltip bottom v-if="nuevaPromo == 1 || (nuevaPromo == 0 && editar == true)">
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="error" @click="quitarArticuloExcept(item)" v-on="on">
                              <v-icon class="mr-2" small>far fa-times-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Quitar</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn color="warning" @click="volverIndex()" class="ma-2">Volver</v-btn>
            <BtnConfirmar
              @action="sendPromocion()"
              color="success"
              :texto="`¿Esta seguro de registrar la nueva Promoción?`"
              nombre="Guardar"
              v-if="nuevaPromo == 1"
            />
            <BtnConfirmar
              @action="updatePromocion()"
              color="success"
              :texto="`¿Esta seguro de aplicar los cambios en la promoción ${promoId}?`"
              nombre="Guardar Cambios"
              v-if="nuevaPromo == 0"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar Articulo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
    <!-- Modal Seleccionar Articulo Exceptuado -->
    <ModalSelectArticulo 
      :datos="dialogSelectArtExcep"
      @setearModalSelectArt="setModalSelectArtExcep"
      @setearArticulo="setArticuloExcep"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import moment from 'moment'
import { roundNumber, cadenaVacia } from '../../util/utils'
import router from '../../router'
import download from 'downloadjs'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      nuevaPromo: 0,
      promoId: 0,
      editar: false,
      descripcion: '',
      vigente: 0,
      descuento: '',
      cantMin: '',
      cantMax: '',
      importeMin: '',
      importeMax: '',
      fechaInicio: moment(new Date()).format('DD/MM/YYYY'),
      fechaFin: moment(new Date()).format('DD/MM/YYYY'),
      conCupones: 0,
      cantCupones: '',
      selectedEmpresa: [],
      empresas: [],
      selectedCanal: [],
      canales: [],
      selectedSucursal: [],
      selectedLocal: [],
      locales: [],
      selectedDia: [],
      dias: [],
      selectedCategoria: [],
      categorias: [],
      artCodigo: '',
      artNombre: '',
      headers: [
        { text: 'Cod. BB', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers2: [
        { text: 'Cod. BB', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Bono', align: 'center', value: 'bono' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Cod. BB', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Categoria', align: 'center', value: 'categoria_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulos: [],
      articulosCopy: [],
      articulosExcep: [],
      load: false,
      loadd: false,
      search: '',
      searchh: '',
      tienePermiso: 0,
      permisoBono: 0,
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      dialogSelectArtExcep: {
        activo: false,
        articulos: [],
        bloquear_cant: true,
        bloquear_precio: true,
        cerrar_seleccion: false,
        titulo_modal: 'Buscar Artículos'
      },
      artRef: "artRef",
      artRefExcep: "artRefExcep",
      reconRef: 'reconRef',
      selectedEstado: {},
      estados: [],
      artCodigoExcep: '',
      artNombreExcep: '',
      bonoProm: ''
    }
  },
  components: {
    FechaPicker,
    SearchDataTable,
    ModalSelectArticulo,
    BtnConfirmar
  },
  created () {
    if (this.$route.hasOwnProperty('params') && Object.keys(this.$route.params).length > 0){
      this.nuevaPromo = 0
      this.promoId = this.$route.params.id
    }else this.nuevaPromo = 1
    this.initForm()
  },
  computed: {
    ...mapState('genericosAcc', ['sucursales'])
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      await this.$store.dispatch('genericosAcc/get_sucursales')
      let initPeticion = await this.$store.dispatch('promociones/initFormUnaPromo', {nuevo: this.nuevaPromo, promocion_id: this.promoId})
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      // canales
      this.canales = initPeticion.canales
      // locales
      this.locales = initPeticion.locales
      // dias
      this.dias = initPeticion.dias
      // categorias
      this.categorias = initPeticion.categorias
      // permiso
      this.tienePermiso = initPeticion.permiso
      // estados
      this.estados = initPeticion.estados
      // permiso para ver / modificar el bono
      this.permisoBono = initPeticion.permiso_bono
      // esta por editar?
      if (this.nuevaPromo == 0) this.completarForm(initPeticion.promocion)
    },
    async completarForm(promo){
      let msjError = ``
      // descripcion
      this.descripcion = promo.promocion.descripcion
      // estado
      this.vigente = promo.promocion.estado == 1 ? 1 : 0
      let unEstado = this.estados.filter(element => element.estado_id == promo.promocion.estado)
      if (unEstado.length > 0) this.selectedEstado = unEstado[0]
      // descuento
      this.descuento = roundNumber(promo.promocion.descuento, 4)
      // fecha inicio y fin
      this.fechaInicio = moment(promo.promocion.fecha_inicio).format("DD/MM/YYYY")
      this.fechaFin = moment(promo.promocion.fecha_fin).format("DD/MM/YYYY")
      // con cupones?
      this.conCupones = promo.promocion.cupon == 1 ? 1 : 0
      // cantidad de cupones
      this.cantCupones = promo.promocion.cant_cupones == null ? '' : promo.promocion.cant_cupones == 0 ? '' : promo.promocion.cant_cupones
      // cantidad minima y maxima
      this.cantMin = promo.promocion.cantidad_minima == null ? '' : promo.promocion.cantidad_minima == 0 ? '' : promo.promocion.cantidad_minima
      this.cantMax = promo.promocion.cantidad_maxima == null ? '' : promo.promocion.cantidad_maxima == 0 ? '' : promo.promocion.cantidad_maxima
      // importe minimo y maximo
      this.importeMin = promo.promocion.importe_min == null ? '' : promo.promocion.importe_min == 0 ? '' : roundNumber(promo.promocion.importe_min, 2)
      this.importeMax = promo.promocion.importe_max == null ? '' : promo.promocion.importe_max == 0 ? '' : roundNumber(promo.promocion.importe_max, 2)
      // empresas
      if (promo.empresas.length > 0){
        let error = 0
        for (let id in promo.empresas){
          let unaEmp = this.empresas.filter(element => element.id == promo.empresas[id].empresa_codigo)
          if (unaEmp.length > 0) this.selectedEmpresa.push(unaEmp[0])
          else error = error + 1
        }
        if (parseInt(error) > 0) msjError = msjError + `Hubieron Empresas de la promoción que no se pudieron mostrar.`
      }
      // canales
      if (promo.canales.length > 0){
        let error = 0
        for (let id in promo.canales){
          let unCanal = this.canales.filter(element => element.canal_id == promo.canales[id].canal_id)
          if (unCanal.length > 0) this.selectedCanal.push(unCanal[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos canales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos canales de la promoción.`
        }
      }
      // sucursales
      if (promo.sucursales.length > 0){
        let error = 0
        for (let id in promo.sucursales){
          let unaSuc = this.sucursales.filter(element => element.id == promo.sucursales[id].sucursal_codigo)
          if (unaSuc.length > 0) this.selectedSucursal.push(unaSuc[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunas sucursales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunas sucursales de la promoción`
        }
      }
      // locales
      if (promo.locales.length > 0){
        let error = 0
        for (let id in promo.locales){
          let unLocal = this.locales.filter(element => element.local_codigo == promo.locales[id].local_codigo)
          if (unLocal.length > 0) this.selectedLocal.push(unLocal[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos locales de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos locales de la promoción.`
        }
      }
      // dias
      if (promo.dias.length > 0){
        let error = 0
        for (let id in promo.dias){
          let unDia = this.dias.filter(element => element.dia_id == promo.dias[id].dia_id)
          if (unDia.length > 0) this.selectedDia.push(unDia[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos días de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos días de la promoción.`
        }
      }
      // categorias
      if (promo.categorias.length > 0){
        let error = 0
        for (let id in promo.categorias){
          let unaCateg = this.categorias.filter(element => element.categoria_codigo == promo.categorias[id].categoria_codigo)
          if (unaCateg.length > 0) this.selectedCategoria.push(unaCateg[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunas categorías de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunas categorías de la promoción.`
        }
      }
      // artículos
      this.load = true
      this.loadd = true
      let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
      this.load = false
      this.loadd = false
      if (articulosPeticion.resultado == 1){
        this.dialogSelectArticulo.articulos = articulosPeticion.articulos
        this.dialogSelectArtExcep.articulos = articulosPeticion.articulos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: articulosPeticion.msj,
          color: 'error',
        })
        return
      }
      if (promo.articulos.length > 0){
        let error = 0
        for (let id in promo.articulos){
          let unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_codigo == promo.articulos[id].articulo_codigo)
          if (unArt.length > 0){
            unArt[0].bono = roundNumber(promo.articulos[id].bono, 2)
            this.articulos.push(unArt[0])
          }
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos artículos de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos artículos de la promoción.`
        }
      }
      this.articulosCopy = JSON.parse(JSON.stringify(this.articulos))
      // artículos exceptuados
      if (promo.articulos_exceptuados.length > 0){
        let error = 0
        for (let id in promo.articulos_exceptuados){
          let unArt = this.dialogSelectArtExcep.articulos.filter(element => element.articulo_codigo == promo.articulos_exceptuados[id].articulo_codigo)
          if (unArt.length > 0) this.articulosExcep.push(unArt[0])
          else error = error + 1
        }
        if (parseInt(error) > 0){
          if (msjError.length > 0) msjError = msjError + ` También, no se pudieron cargar algunos artículos exceptuados de la promoción.`
          else msjError = msjError + `No se pudieron cargar algunos artículos exceptuados de la promoción.`
        }
      }
      if (!cadenaVacia(msjError)){
        this.$store.dispatch('show_snackbar', {
          text: msjError,
          color: 'error',
        })
      }
    },
    volverIndex(){
      router.push({path: '/promociones'})
    },
    async getTodosArticulos(opcion){
      // obtengo los articulos
      if (this.dialogSelectArticulo.articulos.length == 0 || this.dialogSelectArtExcep.articulos.length == 0){
        this.load = true
        this.loadd = true
        let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
        this.load = false
        this.loadd = false
        if (articulosPeticion.resultado == 1){
          this.dialogSelectArticulo.articulos = articulosPeticion.articulos
          this.dialogSelectArtExcep.articulos = articulosPeticion.articulos
        }else{
          this.$store.dispatch('show_snackbar', {
            text: articulosPeticion.msj,
            color: 'error',
          })
          return
        }
      }
      // abro el modal
      if (opcion == 1) this.dialogSelectArticulo.activo = true
      else this.dialogSelectArtExcep.activo = true
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo.activo = value
    },
    setModalSelectArtExcep(value){
      this.dialogSelectArtExcep.activo = value
    },
    setArticulo(art){
      this.addArticulo(art, 1)
    },
    setArticuloExcep(art){
      this.addArticuloExcep(art, 1)
    },
    async addArticulo(art, opcion){
      let articulo = {}
      if (opcion == 0){
        // viene del mismo formulario, ingresaron el código del artículo?
        if (this.artCodigo == null || this.artCodigo == undefined || this.artCodigo.toString().length == 0 || this.artCodigo == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese un Código de Artículo válido.',
            color: 'info',
          })
          this.artCodigo = ''
          return
        }
        // obtengo los articulos si no tengo cargado el array aun
        if (this.dialogSelectArticulo.articulos.length == 0){
          this.load = true
          let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
          this.load = false
          if (articulosPeticion.resultado == 1){
            this.dialogSelectArticulo.articulos = articulosPeticion.articulos
          }else{
            this.$store.dispatch('show_snackbar', {
              text: articulosPeticion.msj,
              color: 'error',
            })
            return
          }
        }
        // hasta aqui ya tengo mis articulos, obtengo los datos solicitados
        let unArt = this.dialogSelectArticulo.articulos.filter(element => element.articulo_codigo == this.artCodigo)
        if (unArt.length > 0) articulo = unArt[0]
        else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró el artículo solicitado.',
            color: 'info',
          })
          this.artCodigo = ''
          return
        }
      }else articulo = art
      this.artNombre = articulo.articulo_nombre
      // 19-07-2024 por MMURILLO, agregamos el campo bono
      articulo.bono = (cadenaVacia(this.bonoProm) || this.bonoProm == 0) ? 0 : roundNumber(this.bonoProm, 2)
      if (roundNumber(articulo.bono, 2) < 0 || roundNumber(articulo.bono, 2) >= 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje inválido. Intente otro valor.',
          color: 'warning',
        })
        return
      }
      // hasta este punto tengo que tener articulo con datos, verifico si ya se encuentra en el listado
      let unArt = this.articulos.filter(element => element.articulo_codigo == articulo.articulo_codigo)
      // verifico si se encuentra en el listado de artículos exceptuados
      let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == articulo.articulo_codigo)
      if (unArt.length > 0 || artExcep.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El artículo ya se encuentra en el listado o bien se encuentra agregado en los artículos exceptuados.',
          color: 'warning',
        })
        this.artCodigo = ''
        this.artNombre = ''
        this.bonoProm = ''
        return
      }else{
        this.articulos.push(articulo)
        this.artCodigo = ''
        this.artNombre = ''
        this.bonoProm = ''
      }
    },
    async addArticuloExcep(art, opcion){
      let articulo = {}
      if (opcion == 0){
        // viene del mismo formulario, ingresaron el código del artículo?
        if (this.artCodigoExcep == null || this.artCodigoExcep == undefined || this.artCodigoExcep.toString().length == 0 || this.artCodigoExcep == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese un Código de Artículo válido.',
            color: 'info',
          })
          this.artCodigoExcep = ''
          return
        }
        // obtengo los articulos si no tengo cargado el array aun
        if (this.dialogSelectArtExcep.articulos.length == 0){
          this.loadd = true
          let articulosPeticion = await this.$store.dispatch('promociones/getAllArticulos')
          this.loadd = false
          if (articulosPeticion.resultado == 1){
            this.dialogSelectArtExcep.articulos = articulosPeticion.articulos
          }else{
            this.$store.dispatch('show_snackbar', {
              text: articulosPeticion.msj,
              color: 'error',
            })
            return
          }
        }
        // hasta aqui ya tengo mis articulos, obtengo los datos solicitados
        let unArt = this.dialogSelectArtExcep.articulos.filter(element => element.articulo_codigo == this.artCodigoExcep)
        if (unArt.length > 0) articulo = unArt[0]
        else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró el artículo solicitado.',
            color: 'info',
          })
          this.artCodigoExcep = ''
          return
        }
      }else articulo = art
      this.artNombreExcep = articulo.articulo_nombre
      // hasta este punto tengo que tener articulo con datos, verifico si ya se encuentra en el listado
      let unArt = this.articulos.filter(element => element.articulo_codigo == articulo.articulo_codigo)
      // verifico si se encuentra en el listado de artículos exceptuados
      let artExcep = this.articulosExcep.filter(element => element.articulo_codigo == articulo.articulo_codigo)
      if (unArt.length > 0 || artExcep.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El artículo ya se encuentra en el listado de los artículos exceptuados o bien ya se encuentra en el listados de los artículos válidos para la promoción.',
          color: 'warning',
        })
        this.artCodigoExcep = ''
        this.artNombreExcep = ''
        return
      }else{
        this.articulosExcep.push(articulo)
        this.artCodigoExcep = ''
        this.artNombreExcep = ''
      }
    },
    quitarArticulo(item){
      let pos = this.articulos.indexOf(item)
      this.articulos.splice(pos, 1)
    },
    quitarArticuloExcept(item){
      let pos = this.articulosExcep.indexOf(item)
      this.articulosExcep.splice(pos, 1)
    },
    validarObligatorios(){
      // debe existir al menos una categoria o un artículo en el detalle
      if (this.selectedCategoria.length == 0 && this.articulos == 0) return {valido: false, msj: 'Seleccione una Categoria o un Artículo.'}
      // control de fechas
      let fechaDesde = moment(this.fechaInicio, "DD/MM/YYYY").toDate()
      let fechaHasta = moment(this.fechaFin, "DD/MM/YYYY").toDate()
      if (fechaDesde > fechaHasta) return {valido: false, msj: 'La fecha de fin debe ser mayor a la fecha de inicio.'}
      // estado
      if (Object.keys(this.selectedEstado).length == 0 && this.nuevaPromo == 0) return {valido: false, msj: 'Debe ingresar el estado de la promoción para continuar.'}
      // descuento
      if (this.descuento == null || this.descuento == undefined || this.descuento == 0 || this.descuento.toString().length == 0) return {valido: false, msj: 'Debe ingresar un descuento válido para continuar.'}
      return {valido: true, msj: 'OK'}
    },
    async sendPromocion(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      // controlo los datos ingresados, la vigencia va a ser 0 es vigente (para ello tiene que tener permiso), 2 es pendiente de aprobar
      let vigente = 1
      if (this.vigente == 1 && this.tienePermiso == 1) vigente = 0
      if (this.tienePermiso != 1) vigente = 2
      // empresas
      let paraEmpresa = 0
      if (this.selectedEmpresa.length > 0) paraEmpresa = 1
      // canales
      let conCanales = 0
      if (this.selectedCanal.length > 0) conCanales = 1
      // sucursales
      let conSucursales = 0
      if (this.selectedSucursal.length > 0) conSucursales = 1
      // locales
      let conLocales = 0
      if (this.selectedLocal.length > 0) conLocales = 1
      // dias
      let conDias = 0
      if (this.selectedDia.length > 0) conDias = 1
      // categorias
      let conCategorias = 0
      if (this.selectedCategoria.length > 0) conCategorias = 1
      // articulos
      let conArticulos = 0
      if (this.articulos.length > 0) conArticulos = 1
      // cupones
      let conCupones = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) conCupones = 1
      // cantidad de cupones
      let cantidadCup = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) cantidadCup = this.cantCupones
      // redondeo el descuento
      let desc = roundNumber(this.descuento, 4)
      // cantidad minima
      let cantMin = 0
      if (this.cantMin != null && this.cantMin != undefined && this.cantMin != 0 && this.cantMin.toString().length > 0) cantMin = this.cantMin
      // cantidad maxima
      let cantMax = 0
      if (this.cantMax != null && this.cantMax != undefined && this.cantMax != 0 && this.cantMax.toString().length > 0) cantMax = this.cantMax
      // importe minimo
      let importeMin = 0
      if (this.importeMin != null && this.importeMin != undefined && this.importeMin != 0 && this.importeMin.toString().length > 0) importeMin = roundNumber(this.importeMin, 2)
      // importe maximo
      let importeMax = 0
      if (this.importeMax != null && this.importeMax != undefined && this.importeMax != 0 && this.importeMax.toString().length > 0) importeMax = roundNumber(this.importeMax, 2)
      // paso a grabar
      let grabarPeticion = await this.$store.dispatch('promociones/sendPromocion', {
        descripcion: this.descripcion,
        vigente: vigente,
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        descuento: desc,
        empresas: this.selectedEmpresa,
        canales: this.selectedCanal,
        sucursales: this.selectedSucursal,
        locales: this.selectedLocal,
        dias: this.selectedDia,
        categorias: this.selectedCategoria,
        cantidad_min: cantMin,
        cantidad_max: cantMax,
        importe_minimo: importeMin,
        importe_maximo: importeMax,
        cantidad_cupones: cantidadCup,
        articulos: this.articulos,
        para_empresas: paraEmpresa,
        para_canales: conCanales,
        para_sucursales: conSucursales,
        para_locales: conLocales,
        para_dias: conDias,
        para_categorias: conCategorias,
        para_articulos: conArticulos,
        para_cupones: conCupones,
        articulos_exceptuados: this.articulosExcep
      })
      this.$store.state.loading = false
      if (grabarPeticion.resultado == 1){
        this.$swal.fire({
          icon: 'success',
          title: `La Promoción ${grabarPeticion.promo_id} se creó exitosamente.`
        })
        // controlo si la promoción fué con cupones
        if (conCupones == 1){
          // descargo el excel
          this.$store.state.loading = true
          let cuponesPeticion = await this.$store.dispatch('promociones/getCuponesPromocion', {promocion_id: grabarPeticion.promo_id})
          this.$store.state.loading = false
          if (cuponesPeticion.resultado == 1){
            download(cuponesPeticion.base64, `Cupones Promocion ${grabarPeticion.promo_id}.xlsx`, 'application/vnd.ms-excel')
          }else{
            this.$store.dispatch('show_snackbar', {
              text: cuponesPeticion.msj,
              color: 'error',
            })
          }
        }
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: grabarPeticion.msj,
          color: 'error',
        })
      }
    },
    async updatePromocion(){
      let valido = this.validarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      // controlo los datos ingresados, vigencia
      let vigente = this.selectedEstado.estado_id
      // empresas
      let paraEmpresa = 0
      if (this.selectedEmpresa.length > 0) paraEmpresa = 1
      // canales
      let conCanales = 0
      if (this.selectedCanal.length > 0) conCanales = 1
      // sucursales
      let conSucursales = 0
      if (this.selectedSucursal.length > 0) conSucursales = 1
      // locales
      let conLocales = 0
      if (this.selectedLocal.length > 0) conLocales = 1
      // dias
      let conDias = 0
      if (this.selectedDia.length > 0) conDias = 1
      // categorias
      let conCategorias = 0
      if (this.selectedCategoria.length > 0) conCategorias = 1
      // articulos
      let conArticulos = 0
      if (this.articulos.length > 0) conArticulos = 1
      // cupones
      let conCupones = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) conCupones = 1
      // cantidad de cupones
      let cantidadCup = 0
      if (this.cantCupones != null && this.cantCupones != undefined && this.cantCupones != 0 && this.cantCupones.toString().length != 0) cantidadCup = this.cantCupones
      // redondeo el descuento
      let desc = roundNumber(this.descuento, 4)
      // cantidad minima
      let cantMin = 0
      if (this.cantMin != null && this.cantMin != undefined && this.cantMin != 0 && this.cantMin.toString().length > 0) cantMin = this.cantMin
      // cantidad maxima
      let cantMax = 0
      if (this.cantMax != null && this.cantMax != undefined && this.cantMax != 0 && this.cantMax.toString().length > 0) cantMax = this.cantMax
      // importe minimo
      let importeMin = 0
      if (this.importeMin != null && this.importeMin != undefined && this.importeMin != 0 && this.importeMin.toString().length > 0) importeMin = roundNumber(this.importeMin, 2)
      // importe maximo
      let importeMax = 0
      if (this.importeMax != null && this.importeMax != undefined && this.importeMax != 0 && this.importeMax.toString().length > 0) importeMax = roundNumber(this.importeMax, 2)
      // paso a grabar
      let updatePeticion = await this.$store.dispatch('promociones/updatePromocion', {
        descripcion: this.descripcion,
        vigente: vigente,
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        descuento: desc,
        empresas: this.selectedEmpresa,
        canales: this.selectedCanal,
        sucursales: this.selectedSucursal,
        locales: this.selectedLocal,
        dias: this.selectedDia,
        categorias: this.selectedCategoria,
        cantidad_min: cantMin,
        cantidad_max: cantMax,
        importe_minimo: importeMin,
        importe_maximo: importeMax,
        cantidad_cupones: cantidadCup,
        articulos: this.articulos,
        para_empresas: paraEmpresa,
        para_canales: conCanales,
        para_sucursales: conSucursales,
        para_locales: conLocales,
        para_dias: conDias,
        para_categorias: conCategorias,
        para_articulos: conArticulos,
        para_cupones: conCupones,
        promocion_id: this.promoId,
        articulos_exceptuados: this.articulosExcep
      })
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: `La Promoción ${this.promoId} se modificó exitosamente.`,
          color: 'success',
        })
        setTimeout(() => [location.reload()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    },
    controlTodasCat(){
      if (this.selectedCategoria == undefined || this.selectedCategoria == null) return
      // compruebo si eligieron TODAS
      let existe = this.selectedCategoria.filter(element => element.categoria_codigo == 0)
      if (existe.length > 0){
        this.selectedCategoria = []
        this.selectedCategoria = this.categorias.filter(element => element.categoria_codigo != 0)
      }
    },
    validarPorcentaje(item){
      if (cadenaVacia(item.bono)) item.bono = 0
      if (roundNumber(item.bono, 2) < 0 || roundNumber(item.bono, 2) >= 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje inválido. Intente otro valor.',
          color: 'warning',
        })
        let existe = this.articulos.filter(element => element.articulo_codigo == item.articulo_codigo)
        let pos = this.articulos.indexOf(existe[0])
        // decido que valor colocar
        let existeCopy = this.articulosCopy.filter(element => element.articulo_codigo == item.articulo_codigo)
        if (existeCopy.length > 0) this.articulos[pos].bono = roundNumber(existeCopy[0].bono, 2)
        else this.articulos[pos].bono = 0
      }else item.bono = roundNumber(item.bono, 2)
    }
  },
  watch: {
    selectedCategoria: function(){
      if (this.selectedCategoria == undefined || this.selectedCategoria == null) this.selectedCategoria = []
    }
  },
}
</script>

<style>

</style>